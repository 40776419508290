import { Injectable, computed, inject, signal } from '@angular/core';
import { switchMap, tap } from 'rxjs';
import { FusionauthService } from '../../client';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class LoginFacadeService {
  _isAuthenticated = signal(false);
  cookieService = inject(CookieService)
  constructor(
    private fusionauthService: FusionauthService,

  ) {}

  setAuthenticatedState(state: boolean): void {
    this._isAuthenticated.set(state);
  }

  public isAuthenticated = computed(() => {
    return this._isAuthenticated();
  });

  getAuthenticatedState(): boolean {
    return this._isAuthenticated();
  }

  login(loginId: string, password: string) {
    return this.fusionauthService.login({ loginId, password }).pipe(
      tap((res) => {
        localStorage.setItem('access_token', res.token);
        this.cookieService.set('access_token', res.token)
      }),
     /*  switchMap(() => this.fusionauthService.getAuthenticatedUser()),
      tap((res) => {
        localStorage.setItem('user', JSON.stringify(res));
      }), */

    );
  }

  get user() {
    const userStorage = localStorage.getItem('user');
    return JSON.parse(userStorage!);
  }
}
